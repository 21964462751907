:root {
    --background: #263E72;

    --white: #fff;
    --cyan: #eeecec;

    --gray: #d3d3d3;
    --gray-100: #e1e1e6;
    --gray-300: #a8a8b3;
    --gray-400: rgb(148 163 184);
    --gray-800: #29292e;
    --gray-850: #1f2729;
    --gray-900: #121214;
    --title-color: #263E72;


    /*========== Font and typography ==========*/
    --body-font: 'Inter', sans-serif;
    --biggest-font-size: 4.5rem;
    --h1-font-size: 3rem;
    --h2-font-size: 2.25rem;
    --h3-font-size: 1.8rem;
    --h4-font-size: 1.2rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-light: 100;
    --font-medium: 400;
    --font-semi-bold: 600;
}

@media screen and (max-width: 600px) {
    :root {
        --biggest-font-size: 3rem;
        --h1-font-size: 2.4rem;
        --h2-font-size: 1.75rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@media (max-width: 1280px) {
    html {
        font-size: 93.75%;
    }
}

@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

@media (max-width: 601px) {
    html {
        font-size: 82%;
    }
}

@media (max-width: 480px) {
    html {
        font-size: 70%;
    }

    img {
        max-width: 90%;
        height: auto;
    }
}

body,
input,
textarea,
button,
label,
h1,
h2,
h3,
h4,
p {
    font-family: var(--body-font);
    font-weight: 400;
}

body {
    background-color: var(--white) !important;
    -webkit-font-smoothing: antialiased;
}

.btn {
    border-radius: 0 !important;
}

nav {
    background-color: var(--background) !important;
}

.navbar-collapse {
    flex-grow: 0 !important;
}

.nav-link {
    text-decoration: none !important;
    color: var(--cyan) !important;
    font-size: var(--h4-font-size) !important;
    font-weight: var(--font-medium) !important;
    padding: 0 12px !important;
    border-bottom: 2px solid transparent !important;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s !important;
}

.nav-link:hover {
    border-bottom: 2px solid var(--gray-300) !important;
}

main {
    margin-top: 105px !important;
}

.section {
    padding: 6.5rem 0;
    min-height: 80vh;
}

.bg-torry {
    background-color: var(--background) !important;
}

.text-torry {
    color: var(--background) !important;
}

h1 {
    font-size: var(--h1-font-size) !important;
    font-weight: var(--font-semi-bold) !important;
    font-family: var(--body-font) !important;
}

h2 {
    font-size: var(--h2-font-size) !important;
    font-weight: var(--font-semi-bold) !important;
    font-family: var(--body-font) !important;
}

.select,
.form-control {
    padding: 1.2rem !important;
    border-radius: 0 !important;
    height: 40px !important;
    font-size: 1.2rem !important;
    border: 1px solid var(--background) !important;
    margin-bottom: 8px;
    width: 100% !important;
}

.form-check-input{
    border: 1px solid var(--background) !important;
}

.content {
    background-color: var(--gray-100);
    border-bottom: 2px solid var(--gray-800);
    border-radius: 22px;
    box-shadow: 0 8px 6px rgba(0, 13, 77, .15);
    margin-bottom: .4rem;
    padding: 1.4rem;
}

.content span,
.content figure i {
    background-color: var(--background);
    padding: .85rem;
    border-radius: 10px;
    color: var(--white);
    font-size: var(--h3-font-size);
    margin-bottom: .2rem;
}

.content-iv {
    background-color: var(--background);
    border-bottom: 2px solid var(--white);
    border-radius: 22px;
    box-shadow: 0 8px 6px rgba(0, 13, 77, .15);
    margin-bottom: .4rem;
    padding: 1.4rem;
}

.content-iv span,
.content-iv figure i {
    background-color: var(--white);
    padding: .85rem;
    border-radius: 10px;
    color: var(--background);
    font-size: var(--h3-font-size);
    margin-bottom: .2rem;
}

#home {
    background-image: url('/images/busy-crowded-traffic-jam-road.jpg');
    background-attachment: fixed;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 40vh;
}

.pricing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.plan {
    background-color: #fff;
    padding: 2.5rem;
    margin: 12px;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
}

.plan:hover {
    box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
}

.plan h2 {
    color: var(--background) !important;
    font-size: var(--h4-font-size) !important;
    font-weight: var(--font-semi-bold) !important;
    font-family: var(--body-font) !important;
}

.price {
    margin-bottom: 1rem;
    font-size: 30px;
}

ul.features {
    list-style-type: none;
    text-align: left;
}

ul.features li {
    margin: 8px;
    font-size: var(--small-font-size);
}

ul.features li .fas {
    margin-right: 4px;
}

ul.features li .fas .fa-check-circle {
    color: #6ab04c;
}

ul.features li .fas .fa-times-circle {
    color: #eb4d4b;
}

.popular {
    border: 2px solid #6ab04c;
    position: relative;
    transform: scale(1.08);
}

.popular span {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #6ab04c;
    color: #fff;
    padding: 4px 20px;
    font-size: 18px;
    border-radius: 5px;
}

footer {
    background-color: var(--gray-850);
    padding: 2.5rem 0;
}

canvas {
    display: block;
    vertical-align: bottom;
}

#ms,
#stats {
    display: none !important;
}

/* ---- particles.js container ---- */

#particles-js {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: var(--gray-300);
    background-image: url('');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.particles-js-canvas-el{
    height: 800px !important;
}

#consulta {
    position: relative;
    z-index: 100;
    background-color: transparent;
    bottom: 600px;
}

#consulta h1,
#consulta h4{
    color: #fff !important;
}

#frequent{
    margin-bottom: 8.5rem;
    height: 30vh;
    margin-top: -180px;
}

@media screen and (max-width: 600px) {
    .section {
        padding: 2.5rem 0;
    }

    main {
        margin-top: 60px !important;
    }

    .navbar-toggler {
        background-color: var(--gray-100) !important;
    }

    #home {
        background-image: url('/images/soft-evening-light-captures_mb.jpeg') !important;
    }

    #particles-js{
        display: none;
    }

    #consulta {
        position: relative;
        z-index: 0;
        background-color: #fff;
        bottom: 0;
    }

    #consulta h1,
    #consulta h4{
        color: var(--background) !important;
    }
}

.parent {
    bottom: 5vh;
    position: fixed;
    right: 8vw;
    z-index: 999;
}

.heart {
    background: #4d9b2e;
    border-radius: 50%;
    cursor: pointer;
    height: 54px;
    line-height: 54px;
    position: relative;
    text-align: center;
    width: 54px;
}

.heart a {
    text-decoration: none;
}

.heart i {
    color: #fff;
    font-size: 22pt;
}


